<template>
  <div class="container">
    <!-- 顶部navbar -->
    <div class="navbar" v-if="!isLogin">
      <div class="content">
        <img
          src="../assets/img/logo.png"
          alt=""
          @click="$router.replace('/home')"
          style="cursor: pointer"
        />
        <div class="navbar-right">
          <div class="navbar-item">
            <a href="javascript:;">下载APP</a>
            <img
              src="../assets/img/xiala.png"
              alt=""
              @mouseenter="isShow = true"
              @mouseleave="isShow = false"
            />
            <img
              v-show="isShow"
              class="download-qr"
              :src="net + about_service.app_download"
              alt=""
              style="background: #fff; padding: 2px"
            />
          </div>
          <div class="navbar-item">
            <router-link to="/aboutUs">关于我们</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/login">登录</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/register">注册</router-link>
          </div>
        </div>
      </div>
    </div>
    <navbar v-else></navbar>
    <div class="help-search">
      <div class="content">
        <h4>公告</h4>
      </div>
    </div>
    <div class="question-section">
      <div
        class="content"
        style="overflow: auto"
        v-infinite-scroll="load"
        :infinite-scroll-immediate="false"
        :infinite-scroll-disabled="disabled"
      >
        <div class="question-item" v-for="(item, index) in list" :key="index">
          <!-- 问题 -->
          <div
            class="item-title"
            @click="
              $router.push({
                path: '/noticeDetail',
                query: { id: item.id },
              })
            "
          >
            <h4>{{ item.title }}</h4>
            <span>{{ (item.create_at * 1000) | format("YYYY-MM-DD") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHome, getNotice } from "@network/home";
import { BASE_URL } from "@constants/constants";
import navbar from "../components/Navbar.vue";
export default {
  name: "AllNotice",
  data() {
    return {
      net: BASE_URL,
      page: 1,
      limit: 5,
      count: 0,
      list: [],
      about_service: {}, //关于和服务
      isShow: false,
    };
  },

  created() {
    this.getHome();
    this.load();
  },

  components: {
    navbar,
  },

  methods: {
    load() {
      this.getNotice();
      this.page++;
    },
    //公告
    getNotice() {
      getNotice(this.page, this.limit).then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: "加载失败",
            offset: 300,
          });
          return false;
        }
        this.count = res.count;
        this.list.push(...res.data);
      });
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: "获取失败",
            offset: 300,
          });
          return false;
        } else {
          this.about_service = res.data.homePage;
        }
      });
    },
  },

  computed: {
    isLogin() {
      let token = this.$storage.get("token");
      return token;
    },
    noMore() {
      //当起始页数大于总页数时停止加载
      return this.list.length >= this.count;
    },
    disabled() {
      return this.noMore;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../assets/css/AllNotice.less";
</style>